






































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Organisation extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public organisation: any = {};
  public subscriptionPlansOptions: any = {};

  public async created() {
    this.refresh();
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;
    this.$store.dispatch('loadOrganisationById', {
      organisationId: this.id,
    }).then((response: any) => {
      this.organisation = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });

    this.$store.dispatch(
      'loadSubscriptionPlansOptions',
    ).then((response: any) => {
      this.subscriptionPlansOptions = response;
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }

  private takeOver() {
    this.$store.dispatch('takeOverOrganisation', {
      organisationId: this.id,
    }).then((response: any) => {
      this.$router.push('/');
      this.$router.go(0);
    }).catch((error: Error) => {
      this.globalError(error);
    });
  }

}
